<template>
    <div class="dubai-banner">
        <h3 class="dubai-banner__title">
            {{ $t('JOIN EVENT IN DUBAI') }}
        </h3>

        <img
            class="dubai-banner__image"
            src="@/assets/images/landing/dubai-banner.png"
            alt=""
        >

        <div class="dubai-banner__timer">
            <div class="dubai-banner__timer-item">
                <span>{{ days }}</span>
                <span>{{ $t('d') }}</span>
            </div>
            <div class="dubai-banner__timer-item">
                <span>{{ hours }}</span>
                <span>{{ $t('h') }}</span>
            </div>
            <div class="dubai-banner__timer-item">
                <span>{{ minutes }}</span>
                <span>{{ $t('m') }}</span>
            </div>
            <div class="dubai-banner__timer-item">
                <span>{{ seconds }}</span>
                <span>{{ $t('s') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'JoinEventInDubai',
    data: () => ({
        dateEnd:  new Date('2024-12-09T23:59:59'),

        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    }),
    mounted() {
        this.countdown()
    },
    methods: {
        countdown() {
            const now = new Date()
            const distance = this.dateEnd - now

            if (distance < 0) {
                clearInterval(this.interval)
                return
            }

            const days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0')
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0')
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0')
            const seconds = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0')

            this.days = days
            this.hours = hours
            this.minutes = minutes
            this.seconds = seconds

            this.interval = setTimeout(this.countdown, 1000)
        }
    }
}
</script>
<style scoped lang="scss">
.dubai-banner {
    min-height: 72px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgb(118,79,191);
    background: linear-gradient(90deg, rgba(118,79,191,1) 0%, rgba(84,80,199,1) 100%);
    overflow: hidden;

    &__title {
        font-family: "Asap Condensed", sans-serif;
        font-size: 28px;
        font-style: italic;
        font-weight: 600;
        line-height: 22px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff
    }

    &__image {
        max-width: 397px;
    }

    &__timer {
        display: flex;
        justify-content: center;
        align-items: center;

        &-item {
            height: 38px;
            width: 56px;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: rgba($color: #fff, $alpha: 0.1);
            border-radius: 10px;
            font-weight: 500;

            & span:first-child {
                color: #fff;
            }

            & span:last-child {
                color: rgba($color: #fff, $alpha: 0.5)
            }
        }

        &-item + &-item {
            margin-left: 8px;
        }
    }
}

@media (max-width: 960px) {
    .dubai-banner {
        flex-direction: column;
        align-items: start;
        padding: 0 16px;
        min-height: 80px;
        position: relative;

        &__title {
            font-size: 24px;
            text-align: center;
        }

        &__image {
            position: absolute;
            right: -243px;
            bottom: 0;
            max-width: 445px;
        }

        &__timer {
            margin-top: 8px;

            &-item {
                font-size: 14px;
                font-weight: 500;

                height: 26px;
                width: 35px;
            }
        }
    }
}
</style>